import React from "react"
import { handleAuthentication } from "../utils/auth"
import { Spin } from "antd"

const Callback = () => {
  handleAuthentication()

  return <Spin />
}

export default Callback
